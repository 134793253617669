<template>
  <div class="wrap x-f" v-loading="loading" id="wrap">
    <!-- 左边树装选择 -->
    <categoryTreeCard
      categoryTreeTitle="收银员分组"
      @treeNodeId="getTreeNodeId"
      @appendTree="appendTree"
      @editTree="editTree"
      @removeTree="removeTree"
      :isEditBtn="true"
      :boxHeight="boxHeight"
      :boxInternalHeight="boxInternalHeight"
      :categoryTreeOptions="userGroupTreeOptions"
      :isOnlyOneLevel="true"
    />
    <!-- 表格和搜索右边盒子 -->
    <div class="rightBox y-b">
      <!-- 表格搜索条件 -->
      <cardTitleCom cardTitle="查询" class="headGoodsSearch" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <!-- 高级搜索按钮 -->
            <seniorSearch
              v-model="queryParams"
              :getList="getList"
              :dropDownList="dropDownList"
              :isAdvancedSearch="false"
              :isBillDate="false"
              :isSearchInput="true"
              isSearchInputTitle="输入收银员编码、名称、手机号"
            />
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="收银员信息" class="headGoodsTable">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <!-- 操作按钮 -->
            <operatingButton
              :getList="getList"
              :isRecycleBtn="true"
              :isDeleteBtn="false"
              :multiple="multiple"
              @handleAdd="handleAdd"
              @handleDelete="handleDelete"
              @handleRedoDelete="dialogOptions.show = true"
            >
              <template slot="specialDiyBtn">
                <el-button
                  type="primary"
                  size="mini"
                  @click="goTo('staffJurisdiction')"
                  >授予权限
                </el-button>
                <el-button
                  type="primary"
                  size="mini"
                  @click="goTo('groupJurisdiction')"
                  >分组权限
                </el-button>
                <el-button type="primary" size="mini" @click="copyPermission"
                  >复制权限
                </el-button>
              </template>
            </operatingButton>
            <div>
              <el-table
                v-loading="loadingTable"
                :data="tableData"
                @selection-change="handleSelectionChange"
                @row-click="handleRowClick"
                border
                :max-height="tableHeight"
                :height="tableHeight"
                width="100%"
                ref="multipleTable"
              >
                <el-table-column type="selection" width="50" align="center" />
                <el-table-column
                  align="center"
                  type="index"
                  width="80"
                  label="序号"
                >
                </el-table-column>
                <el-table-column
                  label="收银员编号"
                  align="center"
                  key="cashierNo"
                  prop="cashierNo"
                  width="180"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row)"
                      target="_blank"
                    >
                      {{ scope.row.cashierNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  label="收银员姓名"
                  align="center"
                  key="cashierName"
                  prop="cashierName"
                  width="180"
                />
                <el-table-column
                  label="手机号"
                  align="center"
                  key="telephone"
                  prop="telephone"
                  width="150"
                />
                <el-table-column
                  label="所属门店"
                  align="center"
                  key="shopNameStr"
                  prop="shopNameStr"
                  width="140"
                />
                <el-table-column
                  label="备注"
                  align="center"
                  key="remark"
                  prop="remark"
                  :show-overflow-tooltip="true"
                />

                <el-table-column
                  label="修改人"
                  align="center"
                  key="updateBy"
                  prop="updateBy"
                  :show-overflow-tooltip="true"
                  width="140"
                />
                <el-table-column
                  label="修改时间"
                  align="center"
                  prop="updateTime"
                  show-overflow-tooltip
                  width="155"
                >
                </el-table-column>
                <el-table-column
                  label="创建人"
                  align="center"
                  key="createBy"
                  prop="createBy"
                  :show-overflow-tooltip="true"
                  width="140"
                />
                <el-table-column
                  label="创建时间"
                  align="center"
                  prop="createTime"
                  show-overflow-tooltip
                  width="155"
                >
                </el-table-column>
              </el-table>
              <bottomPage
                v-model="queryParams"
                :getList="getList"
                :idsCom="ids"
                :totalCom="total"
                @selectAllCom="selectAll"
              />
            </div>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <!-- 添加或修改收银员对话框 -->
    <c-dialog :title="cashierTitle" :width="600" :showDialog.sync="openCashier">
      <template #content>
        <el-form
          ref="cashierForm"
          :model="cashierForm"
          :rules="rules"
          label-width="100px"
          v-loading="loadingDetail"
        >
          <el-row>
            <el-col :span="20">
              <el-form-item label="用户组" prop="cashierGroupId">
                <treeselect
                  @input="inputSelect"
                  ref="treeCashier"
                  v-model="cashierForm.cashierGroupId"
                  :options="userGroupTreeOptions"
                  :show-count="true"
                  placeholder="请选择收银员用户组"
                  :normalizer="normalizer"
                  :default-expand-all="true"
                  :clearable="false"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="收银员" prop="cashierId">
                <SelectRemote
                  @selectChange="tenantEmployeeChange"
                  v-model="cashierForm.cashierId"
                  :option="
                    $select({
                      key: 'listEmployee',
                      option: {
                        option: {
                          showItem: [
                            {
                              employeeId: cashierForm.cashierId,
                              employeeName: cashierForm.cashierName
                            }
                          ],
                          labels: [
                            { title: '员工编号', label: 'employeeNo' },
                            { title: '员工名称', label: 'employeeName' }
                          ]
                        }
                      }
                    }).option
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="收银员编号" prop="cashierNo">
                <el-input
                  v-model="cashierForm.cashierNo"
                  placeholder="收银员编号"
                  maxlength="11"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="手机号" prop="telephone">
                <el-input
                  :disabled="true"
                  v-model="cashierForm.telephone"
                  placeholder="手机号"
                  maxlength="11"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="23">
              <el-form-item label="门店" prop="shopIdArr">
                <div class="addShop">
                  <SelectLocal
                    :multiple="true"
                    v-model="cashierForm.shopIdArr"
                    :option="{
                      disabled: disabledShop,
                      remote: getAllShopInfo,
                      labels: [
                        { title: '门店编号', label: 'shopNo' },
                        { title: '门店名称', label: 'shopName' }
                      ],
                      value: 'shopId',
                      label: 'shopName'
                    }"
                  />
                  <el-checkbox v-model="checkedAllShop" class="allShop"
                    >全部
                  </el-checkbox>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="cashierForm.remark"
                  type="textarea"
                  placeholder="备注长度介于 1 和 30 字符之间"
                  maxlength="30"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <div
            v-if="cashierTitle === '新增收银员管理'"
            style="textalign: center; color: #b6b6b6; margin-left: 100px"
          >
            说明: 将生成随机密码并发送短信消息到收银员手机号上 !
          </div>
        </el-form>
      </template>
      <template #buttonList>
        <el-button size="mini" class="marL10" @click="closeDia">取消</el-button>
        <el-button size="mini" type="primary" @click="saveAndAdd" class="marL10"
          >保存并新增</el-button
        >
        <el-button
          size="mini"
          type="primary"
          @click="submitForm(false)"
          class="marL10"
          >保存</el-button
        >
      </template>
    </c-dialog>
    <!-- 添加或修改用户组对话框 -->
    <c-dialog
      :title="userGroupTitle"
      :width="600"
      :showDialog.sync="openUserGroup"
    >
      <template #content>
        <el-form
          v-loading="userGroupLoading"
          :model="userGroupForm"
          ref="userGroupForm"
          label-width="120px"
        >
          <el-form-item label="父用户组名称" prop="parentCashierGroupName">
            <el-input
              v-model="userGroupForm.parentCashierGroupName"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户组编号" prop="cashierGroupNo">
            <el-input
              v-model="userGroupForm.cashierGroupNo"
              autocomplete="off"
              placeholder="请输入用户组编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户组名称" prop="cashierGroupName">
            <el-input
              v-model="userGroupForm.cashierGroupName"
              placeholder="请输入用户组名称"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button size="mini" class="marL10" @click="closeDia2"
          >取 消</el-button
        >
        <!-- <el-button
          size="mini"
          type="primary"
          @click="groupSaveAndAdd"
          class="marL10"
          >保存并新增</el-button
        > -->
        <el-button
          size="mini"
          type="primary"
          @click="groupSubmitForm(false)"
          class="marL10"
          >保 存</el-button
        >
      </template>
    </c-dialog>
    <!-- 复制权限对话框 -->
    <c-dialog title="复制权限" :width="400" :showDialog.sync="permissionOpen">
      <template #content>
        <el-form
          :model="permissionForm"
          ref="permissionForm"
          label-width="90px"
        >
          <el-form-item label="复制对象" prop="sourceCashierId">
            <SelectRemote
              v-model="permissionForm.sourceCashierId"
              style="width: 250px"
              :option="
                $select({
                  key: 'listCashier',
                  option: {
                    option: {
                      buttons: [
                        {
                          type: 'more',
                          option: {
                            title: '选择收银员',
                            width: 1250,
                            type: 'TreeAndTable',
                            formData: cashierDialog
                          }
                        }
                      ]
                    }
                  }
                }).option
              "
            />
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button size="mini" class="marL10" @click="permissionOpen = false"
          >取 消</el-button
        >
        <el-button
          size="mini"
          type="primary"
          @click="submitPermissionForm"
          class="marL10"
          >确 定</el-button
        >
      </template>
    </c-dialog>
    <Dialog2 :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog2 from '@/components/Dialog' //回收站
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {
  listShopCashier,
  addShopCashier,
  delShopCashier,
  getShopCashierDetail,
  updateShopCashier,
  restoreCashier,
  copyPermissions
} from '@/api/shop/base/shopCashier'
import {
  addShopCashierGroup,
  delShopCashierGroup,
  getShopCashierGroupDetail,
  updateShopCashierGroup,
  getCashierTree
} from '@/api/shop/base/shopCashierGroup'
import { getAllTenantEmployeeByName } from '@/api/system/employee'
import { getAllShopInfo } from '@/api/shop/base/shopInfo'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import categoryTreeCard from '@/views/components/categoryTreeCard' //选择类别
import bottomPage from '@/views/components/bottomPage' //底部分页
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import operatingButton from '@/views/components/operatingButton' //操作按钮
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
export default {
  name: 'ShopBaseCashier',
  components: {
    Treeselect,
    CDialog,
    cardTitleCom,
    categoryTreeCard,
    bottomPage,
    seniorSearch,
    operatingButton,
    Dialog2,
    SelectLocal,
    SelectRemote
  },
  data () {
    return {
      getAllShopInfo,
      userGroupLoading: false, //收银组
      cashierDialog: this.$dialog({
        key: 'cashier',
        option: {
          table: {
            ...this.$dialog({ key: 'cashier' }).table,
            radioSelect: true,
            mutiSelect: false
          }
        }
      }),
      //复制权限
      permissionForm: {
        sourceCashierId: undefined
      },
      //权限
      permissionOpen: false,
      //详情弹窗
      loadingDetail: false,
      dialogOptions: {
        type: 'ShopInfo',
        show: false,
        title: '回收站',
        width: '75vw',
        data: {
          getListApi: listShopCashier,
          restoreListApi: restoreCashier,
          id: 'cashierId',
          search: [
            {
              type: 'filters',
              isRestore: true,
              model: '',
              filters: [
                { filter: 'query', label: '全部' },
                { filter: 'cashierNos', label: '收银员编码' },
                { filter: 'cashierNames', label: '收银员名称' },
                { filter: 'telephones', label: '手机号' }
              ]
            },
            {
              type: 'button',
              tip: '查询',
              btnType: 'primary',
              click: 'search',
              isRestore: true
            }
          ],
          columns: [
            {
              prop: 'cashierNo',
              label: '收银员编号',
              minWidth: 180
            },
            {
              prop: 'cashierName',
              label: '收银员姓名',
              minWidth: 180
            },
            {
              prop: 'telephone',
              label: '手机号',
              minWidth: 100
            },
            {
              prop: 'shopNameStr',
              label: '所属门店',
              minWidth: 100
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 120
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 120
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 155
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 120
            },
            {
              prop: 'createBy',
              label: '创建时间',
              minWidth: 155
            }
          ]
        }
      },
      loadingTable: false,
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'cashierNos', name: '收银员编码' },
        { parameter: 'cashierNames', name: '收银员名称' },
        { parameter: 'telephones', name: '手机号' }
      ],
      disabledShop: true, //门店选择
      checkedAllShop: true, //全部门店
      isOpenTree: true, //一键展开图标
      isEdit: true, //编辑
      //是否一键展开
      isExpand: true,
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      //选中数组的名称
      name: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      //收银员表格数据
      tableData: [],
      // 收银员表格总条数
      total: 0,
      // 收银员对话框标题
      cashierTitle: '',
      // 用户组对话框标题
      userGroupTitle: '',
      //是否显示收银员对话框
      openCashier: false,
      //是否显示用户组对话框
      openUserGroup: false,
      //用户组对话框名称数据
      options: [],
      // 收银员用户组tree数据
      userGroupTreeOptions: [],
      // 用户组tree搜索的名称
      userGroupName: undefined,
      //tree选中的id
      selectTreeId: undefined,
      // 收银员的操作类型(add、update)
      action: null,
      // 收银员分组的操作类型(add、update)
      actionGroup: null,
      // 收银员表单
      cashierForm: { cashierGroupId: -1, shopIdArr: [] },
      //所有的店铺
      shopInfoData: [],
      // 用户组表单
      userGroupForm: {
        parentCashierGroupNo: null,
        parentCashierGroupName: null,
        cashierGroupNo: null,
        cashierGroupName: null
      },
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      selectData: {}, // 选择的数据
      // 表单校验
      rules: {
        cashierId: [
          { required: true, message: '收银员不能为空', trigger: 'blur' }
        ],
        cashierNo: [
          {
            required: true,
            message: '收银员编号不能为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^[A-Za-z0-9]{1,20}$/,
            message: '请输入1 ~ 20个数值或英文的字符'
          }
        ],
        shopIdArr: [
          {
            required: false,
            message: '门店不能为空',
            trigger: 'blur'
          }
        ]
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  watch: {
    // 根据名称筛选分类树
    categoryName (val) {
      this.$refs.tree.filter(val)
    },
    dateRange (val) {
      this.queryParams.beginTime = val[0]
      this.queryParams.endTime = val[1]
    },
    //全部门店
    checkedAllShop (val) {
      if (this.checkedAllShop) {
        this.cashierForm.shopIdArr = []
        this.disabledShop = true
        this.rules.shopIdArr[0].required = false
      } else {
        this.disabledShop = false
        this.rules.shopIdArr[0].required = true
      }
    }
  },
  created () {
    this.loading = true
    this.getList()
    this.getTreeselect()
    this.loading = false
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 140
    },
    boxHeight () {
      return this.vivwH - 10
    },
    boxInternalHeight () {
      return this.vivwH - 83
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    //确认复制权限
    async submitPermissionForm () {
      if (!this.permissionForm.sourceCashierId)
        return this.$message.warning('请选择收银员')
      await copyPermissions({
        sourceCashierId: this.permissionForm.sourceCashierId,
        repeatedCashierId: this.ids
      })
      this.permissionOpen = false
      await this.getList()
      this.$message.success('复制成功')
    },
    //复制权限
    copyPermission () {
      if (this.ids.length <= 0)
        return this.$message.warning('请选择要执行的数据')
      this.permissionForm.sourceCashierId = undefined
      this.permissionOpen = true
    },
    //还原刷新页面
    handleEvent () {
      this.loading = true
      this.getTreeselect()
      this.getList()
      this.loading = false
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //点击全选所有数据
    async selectAll (value) {
      if (value) {
        this.loadingTable = true
        const res = await listShopCashier({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    // 下拉框校验方法
    inputSelect () {
      this.$refs.cashierForm.validateField('cashierGroupId')
    },
    getSelectUpdate (val) {
      //解决：el-select下拉框多选，选择后赋值成功，输入框不显示选中的值
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    tenantEmployeeChange (employeeId, tenantEmployee) {
      console.log('tenantEmployee', tenantEmployee)
      this.cashierForm.cashierName = tenantEmployee.employeeName
      this.cashierForm.telephone = tenantEmployee
        ? tenantEmployee.telephone
        : ''
    },
    //treeSelect结构属性转换
    normalizer (node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children
      }
    },
    //新增Tree用户组
    appendTree (data) {
      this.userGroupTitle = '新增收银员用户组'
      this.openUserGroup = true
      this.userGroupForm.parentCashierGroupId = data.id
      this.userGroupForm.parentCashierGroupName = data.label
      this.userGroupForm.cashierGroupNo = null
      this.userGroupForm.cashierGroupName = null
      this.resetForm('userGroupForm')
      this.actionGroup = 'add'
    },
    //编辑Tree用户组
    async editTree (data) {
      try {
        //获取用户组明细
        if (data.id == -1) {
          return
        }
        this.openUserGroup = true
        this.userGroupLoading = true
        this.userGroupForm.cashierGroupNo = null
        this.userGroupForm.cashierGroupName = null
        this.resetForm('userGroupForm')
        /** 获取用户组明细 */
        const res = await getShopCashierGroupDetail(data.id)
        this.userGroupForm = res.data
        this.userGroupTitle = '编辑收银员用户组'
        this.actionGroup = 'update'
        this.userGroupLoading = false
      } catch (error) {
        this.userGroupLoading = false
      }
    },
    //删除Tree用户组
    removeTree ({ node, data }) {
      console.log(node, data)
      if (data.id == -1) {
        return
      }
      this.$confirm(`确定删除(${node.data.label})用户组,是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        if (node.data.children) {
          this.$message({
            howClose: true,
            message: '请先删除子级用户组',
            type: 'warning'
          })
          return
        }
        var cashierGroupIds = [node.data.id]
        delShopCashierGroup(cashierGroupIds).then(response => {
          this.$modal.msgSuccess('删除成功')
          this.getTreeselect()
        })
      })
    },
    /** 查询收银员列表 */
    getList () {
      this.loadingTable = true
      listShopCashier(this.queryParams).then(response => {
        this.tableData = response.rows
        this.total = response.total
        this.loadingTable = false
      })
    },

    /** 查询下拉树结构 */
    getTreeselect () {
      getCashierTree().then(response => {
        this.userGroupTreeOptions = response.data
      })
    },

    // 用户组节点单击事件
    getTreeNodeId (value) {
      this.queryParams.cashierGroupId = value
      this.getList()
    },
    //收银分组取消
    groupCancel () {
      this.openUserGroup = false
    },
    //收银分组提交
    groupSubmitForm (isResetGroup = false) {
      this.$refs['userGroupForm'].validate(valid => {
        if (valid) {
          this.userGroupForm.parentId = this.userGroupForm.parentCashierGroupId
          if (this.actionGroup == 'update') {
            updateShopCashierGroup(this.userGroupForm).then(async response => {
              if (!isResetGroup) {
                this.$modal.msgSuccess('修改成功')
                this.openUserGroup = false
              } else {
                this.$modal.msgSuccess('保存并新增成功')
              }
              await this.getTreeselect()
            })
          } else {
            addShopCashierGroup(this.userGroupForm).then(async response => {
              if (!isResetGroup) {
                this.$modal.msgSuccess('新增成功')
                this.openUserGroup = false
              } else {
                this.$modal.msgSuccess('保存并新增成功')
              }
              await this.getTreeselect()
            })
          }
        }
      })
    },
    async groupSaveAndAdd () {
      try {
        this.userGroupLoading = true
        await this.groupSubmitForm(true)
        this.userGroupForm.cashierGroupNo = null
        this.userGroupForm.cashierGroupName = null
        this.resetForm('userGroupForm')
        this.$modal.msgSuccess('保存新增成功')
        this.userGroupLoading = false
      } catch (error) {
        this.userGroupLoading = false
      }
    },
    // 表单重置
    reset () {
      this.cashierForm = {
        cashierGroupId: -1,
        cashierId: undefined,
        cashierNo: undefined,
        telephone: undefined,
        shopIdArr: [],
        remark: undefined
      }
      //全部门店
      this.checkedAllShop = true
      //禁用选择门店
      this.disabledShop = true
      this.resetForm('cashierForm')
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      console.log(selection)
      this.selectData = {
        // 设置权限使用
        cid: selection[0]?.cashierId,
        userName: selection[0]?.cashierName
      }
      this.ids = selection.map(item => item.cashierId)
      this.cashierName = selection.map(item => item.cashierName)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    //获取所有
    /** 新增按钮操作 */
    handleAdd () {
      this.reset()
      this.openCashier = true
      this.cashierTitle = '新增收银员管理'
      this.action = 'add'
    },

    //  修改\点击编号
    async handleDetail (row) {
      this.reset()
      this.cashierTitle = '编辑收银员管理'
      this.action = 'update'
      this.openCashier = true
      this.loadingDetail = true
      getShopCashierDetail(row.cashierId).then(response => {
        if (response.data.shopIdStr === '0') {
          response.data.shopIdArr = []
          this.checkedAllShop = true
        } else {
          response.data.shopIdArr = response.data?.shopIdStr
            ?.split?.(',')
            ?.map?.(x => Number(x))
          this.checkedAllShop = false
        }
        this.cashierForm = response.data
        this.loadingDetail = false
      })
    },
    /** 提交按钮 */
    async submitForm (bol = false) {
      try {
        this.loadingDetail = true
        this.$refs['cashierForm'].validate(async valid => {
          if (valid) {
            if (this.checkedAllShop) {
              this.cashierForm.shopIdStr = '0'
            } else {
              this.cashierForm.shopIdStr = this.cashierForm.shopIdArr.join(',')
            }
            if (this.action == 'update') {
              await updateShopCashier(this.cashierForm)
              if (!bol) {
                this.$modal.msgSuccess('修改成功')
                this.openCashier = false
              } else {
                this.reset()
                this.$modal.msgSuccess('保存新增成功')
              }
              await this.getList()
            } else {
              await addShopCashier(this.cashierForm)
              if (!bol) {
                this.$modal.msgSuccess('新增成功')
                this.openCashier = false
              } else {
                this.reset()
                this.$modal.msgSuccess('保存新增成功')
              }
              await this.getList()
            }
          }
        })
      } catch (error) {
      } finally {
        this.loadingDetail = false
      }
    },
    // 保存并新增
    async saveAndAdd () {
      await this.submitForm(true)
    },
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        'api/system/shop/base/cashier/export',
        {
          ...this.queryParams
        },
        `cashier_${new Date().getTime()}.xlsx`
      )
    },
    /** 删除按钮操作 */
    handleDelete () {
      const cashierIds = this.ids
      console.log(cashierIds)
      this.$modal
        .confirm('是否确认删除收银员为"' + this.cashierName + '"的数据项？')
        .then(function () {
          delShopCashier(cashierIds).then(response => {
            this.getList()
            this.$modal.msgSuccess('删除成功')
          })
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('删除成功')
        })
        .catch(() => {})
    },
    // 跳转权限
    goTo (name) {
      if (name == 'staffJurisdiction') {
        if (this.ids.length == 1) {
          this.$router.push({
            path: '/shop/base/cashier/staffJurisdiction',
            query: {
              cid: this.selectData.cid,
              userName: this.selectData.userName
            }
          })
        } else {
          this.$message.warning('请选择一个收银员')
        }
      } else {
        console.log('=', this.userGroupTreeOptions)
        if (this.userGroupTreeOptions[0]?.children?.length > 0) {
          this.$router.push({
            path: '/shop/base/cashier/groupJurisdiction'
          })
        } else {
          this.$message.warning('请先创建收银分组')
        }
      }
    },
    // 关闭弹窗
    closeDia () {
      this.openCashier = false
    },
    closeDia2 () {
      this.openUserGroup = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 右边
  .rightBox {
    height: calc(100vh - 98px);
    width: calc(100% - 354px); //商品搜索条件
    .headGoodsSearch {
      width: 100%;
      // 搜索
      .navSearch {
        padding: 10px 10px 10px 20px;
        //筛选条件
        .searchCriteria {
          padding-top: 10px;
          .selectFrameUp {
            display: inline-block;
            margin-right: 15px;
          }
        }
      }
    }
    //商品表格卡片
    .headGoodsTable {
      height: calc(100% - 94px);
      margin-bottom: 0;
      flex: 1;
      .goodsMessage {
        margin: 6px 10px 0 10px;
      }
    }
  }
}
.addShop {
  display: flex;

  .allShop {
    margin-left: 7px;
  }
}
</style>
