var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "container padd10",
      },
      [
        _c(
          "div",
          { staticClass: "x-c-end", staticStyle: { width: "100%" } },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.save },
              },
              [_vm._v("保存")]
            ),
            _c(
              "el-button",
              { attrs: { size: "mini" }, on: { click: _vm.grantAll } },
              [_vm._v("授予所有权限")]
            ),
            _c(
              "el-button",
              { attrs: { size: "mini" }, on: { click: _vm.cancelAll } },
              [_vm._v("取消所有权限")]
            ),
            _c(
              "el-button",
              { attrs: { size: "mini" }, on: { click: _vm.getQuit } },
              [_vm._v("退出")]
            ),
          ],
          1
        ),
        _c(
          "el-form",
          {
            ref: "formRes",
            staticClass: "marT10",
            attrs: { "label-width": "120px" },
            model: {
              value: _vm.form,
              callback: function ($$v) {
                _vm.form = $$v
              },
              expression: "form",
            },
          },
          [
            _c("cardTitleCom", {
              attrs: { cardTitle: "授权人" },
              scopedSlots: _vm._u([
                {
                  key: "cardContent",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "padd10" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "被授权用户组" } },
                            [
                              _c("treeselect", {
                                ref: "treeCashier",
                                staticStyle: { width: "220px" },
                                attrs: {
                                  options: _vm.userGroup,
                                  "show-count": true,
                                  "disable-branch-nodes": true,
                                  placeholder: "请选择收银员用户组",
                                  normalizer: _vm.normalizer,
                                  "default-expand-all": true,
                                  appendToBody: true,
                                },
                                on: {
                                  input: _vm.inputSelect,
                                  blur: _vm.inputChange,
                                },
                                model: {
                                  value: _vm.form.cid,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "cid", $$v)
                                  },
                                  expression: "form.cid",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _c("cardTitleCom", {
              attrs: { cardTitle: "价格限制" },
              scopedSlots: _vm._u([
                {
                  key: "cardContent",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "padd10" },
                        [
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 2 } }, [
                                _c("div", { staticClass: "column" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "fontS14",
                                      staticStyle: {
                                        width: "130px",
                                        "text-align": "right",
                                      },
                                    },
                                    [_vm._v(" 折扣/折让权限 ")]
                                  ),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "marL50",
                                        staticStyle: { width: "120px" },
                                        on: { change: _vm.priceCheckAllFn1 },
                                        model: {
                                          value:
                                            _vm.form.pricePermissionsReq
                                              .isAllowedDiscountCheckAll,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.pricePermissionsReq,
                                              "isAllowedDiscountCheckAll",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.pricePermissionsReq.isAllowedDiscountCheckAll\n                    ",
                                        },
                                      },
                                      [_vm._v("全选 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.pricePermissionsReq
                                              .isAllowedSingleDiscount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.pricePermissionsReq,
                                              "isAllowedSingleDiscount",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.pricePermissionsReq.isAllowedSingleDiscount",
                                        },
                                      },
                                      [_vm._v("单品折扣 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-f" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "fontS14",
                                        staticStyle: {
                                          width: "220px",
                                          "text-align": "right",
                                        },
                                      },
                                      [_vm._v(" 可对单商品打最高折扣（折） ")]
                                    ),
                                    _c("el-input", {
                                      staticStyle: { width: "160px" },
                                      attrs: {
                                        size: "mini",
                                        placeholder: "请输入折扣0-100%",
                                      },
                                      model: {
                                        value:
                                          _vm.form.pricePermissionsReq
                                            .singleGoodsDiscount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.pricePermissionsReq,
                                            "singleGoodsDiscount",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.pricePermissionsReq.singleGoodsDiscount",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 4 } }, [
                                _c("div", { staticClass: "column x-c" }, [
                                  _c("div", {
                                    staticClass: "grid-content bg-purple-light",
                                  }),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "marT10" },
                            [
                              _c("el-col", { attrs: { span: 2 } }, [
                                _c("div", { staticClass: "column x-c" }, [
                                  _c("div", {
                                    staticClass: "fontS14",
                                    staticStyle: {
                                      width: "150px",
                                      height: "30px",
                                    },
                                  }),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c("div", { staticClass: "column x-c" }, [
                                  _c("div", { staticClass: "marL50 width120" }),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.pricePermissionsReq
                                              .isAllowedWholeOrderDiscount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.pricePermissionsReq,
                                              "isAllowedWholeOrderDiscount",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.pricePermissionsReq.isAllowedWholeOrderDiscount\n                    ",
                                        },
                                      },
                                      [_vm._v("整单折扣 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-f" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "fontS14",
                                        staticStyle: {
                                          width: "220px",
                                          "text-align": "right",
                                        },
                                      },
                                      [_vm._v(" 可对整单打最高折扣（折） ")]
                                    ),
                                    _c("el-input", {
                                      staticStyle: { width: "160px" },
                                      attrs: {
                                        size: "mini",
                                        placeholder: "请输入折扣0-100%",
                                      },
                                      model: {
                                        value:
                                          _vm.form.pricePermissionsReq
                                            .wholeOrderDiscount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.pricePermissionsReq,
                                            "wholeOrderDiscount",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.pricePermissionsReq.wholeOrderDiscount",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 4 } }, [
                                _c("div", { staticClass: "column x-c" }, [
                                  _c("div", {
                                    staticClass: "grid-content bg-purple-light",
                                  }),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "marT10" },
                            [
                              _c("el-col", { attrs: { span: 2 } }, [
                                _c("div", { staticClass: "column x-c" }, [
                                  _c("div", {
                                    staticClass: "fontS14",
                                    staticStyle: {
                                      width: "150px",
                                      height: "30px",
                                    },
                                  }),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c("div", { staticClass: "column x-c" }, [
                                  _c("div", { staticClass: "marL50 width120" }),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.pricePermissionsReq
                                              .isAllowedWholeOrderAllowances,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.pricePermissionsReq,
                                              "isAllowedWholeOrderAllowances",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.pricePermissionsReq.isAllowedWholeOrderAllowances\n                    ",
                                        },
                                      },
                                      [_vm._v("整单议价 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-f" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "fontS14",
                                        staticStyle: {
                                          width: "220px",
                                          "text-align": "right",
                                        },
                                      },
                                      [_vm._v(" 最大优惠金额（元） ")]
                                    ),
                                    _c("el-input", {
                                      staticStyle: { width: "160px" },
                                      attrs: {
                                        size: "mini",
                                        placeholder: "请输入金额",
                                      },
                                      model: {
                                        value:
                                          _vm.form.pricePermissionsReq
                                            .wholeOrderAllowances,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.pricePermissionsReq,
                                            "wholeOrderAllowances",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.pricePermissionsReq.wholeOrderAllowances",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 4 } }, [
                                _c("div", { staticClass: "column x-c" }, [
                                  _c("div", {
                                    staticClass: "grid-content bg-purple-light",
                                  }),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "marT10" },
                            [
                              _c("el-col", { attrs: { span: 2 } }, [
                                _c("div", { staticClass: "column x-c" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "fontS14",
                                      staticStyle: {
                                        width: "150px",
                                        height: "30px",
                                        "text-align": "right",
                                      },
                                    },
                                    [_vm._v(" 退货权限 ")]
                                  ),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "marL50",
                                        staticStyle: { width: "120px" },
                                        on: { change: _vm.priceCheckAllFn2 },
                                        model: {
                                          value:
                                            _vm.form.pricePermissionsReq
                                              .returnAuthorityCheckAll,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.pricePermissionsReq,
                                              "returnAuthorityCheckAll",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.pricePermissionsReq.returnAuthorityCheckAll",
                                        },
                                      },
                                      [_vm._v("全选 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.pricePermissionsReq
                                              .isAllowedReturn,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.pricePermissionsReq,
                                              "isAllowedReturn",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.pricePermissionsReq.isAllowedReturn",
                                        },
                                      },
                                      [_vm._v("退货 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-f" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "fontS14",
                                        staticStyle: {
                                          width: "220px",
                                          "text-align": "right",
                                        },
                                      },
                                      [_vm._v(" 最高退货退款金额（元） ")]
                                    ),
                                    _c("el-input", {
                                      staticStyle: { width: "160px" },
                                      attrs: {
                                        size: "mini",
                                        placeholder: "请输入金额",
                                      },
                                      model: {
                                        value:
                                          _vm.form.pricePermissionsReq
                                            .maximumReturnRefundAmount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.pricePermissionsReq,
                                            "maximumReturnRefundAmount",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                      form.pricePermissionsReq.maximumReturnRefundAmount\n                    ",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-f" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "fontS14 x-c",
                                        staticStyle: { width: "100px" },
                                      },
                                      [_vm._v(" 最多能退最近 ")]
                                    ),
                                    _c("el-input", {
                                      staticClass: "width80",
                                      attrs: {
                                        size: "mini",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value:
                                          _vm.form.pricePermissionsReq
                                            .allowRefundNearestDay,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.pricePermissionsReq,
                                            "allowRefundNearestDay",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.pricePermissionsReq.allowRefundNearestDay",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "fontS14 x-c marL5",
                                        staticStyle: { width: "160px" },
                                      },
                                      [_vm._v(" 天的零售单（仅销售） ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 2 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c width180" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        model: {
                                          value:
                                            _vm.form.pricePermissionsReq
                                              .isAllowedCancelNoSourceOrder,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.pricePermissionsReq,
                                              "isAllowedCancelNoSourceOrder",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.pricePermissionsReq.isAllowedCancelNoSourceOrder\n                    ",
                                        },
                                      },
                                      [_vm._v("无票退货权限 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "marT10" },
                            [
                              _c("el-col", { attrs: { span: 2 } }, [
                                _c("div", { staticClass: "column x-c" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "fontS14",
                                      staticStyle: {
                                        width: "150px",
                                        height: "30px",
                                        "text-align": "right",
                                      },
                                    },
                                    [_vm._v(" 赠送权限 ")]
                                  ),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "marL50 width120",
                                        on: { change: _vm.priceCheckAllFn3 },
                                        model: {
                                          value:
                                            _vm.form.pricePermissionsReq
                                              .giftPermissionCheckAll,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.pricePermissionsReq,
                                              "giftPermissionCheckAll",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.pricePermissionsReq.giftPermissionCheckAll",
                                        },
                                      },
                                      [_vm._v("全选 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.pricePermissionsReq
                                              .isAllowedQuickGifts,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.pricePermissionsReq,
                                              "isAllowedQuickGifts",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.pricePermissionsReq.isAllowedQuickGifts",
                                        },
                                      },
                                      [_vm._v("快速赠品 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-f" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "fontS14",
                                        staticStyle: {
                                          width: "220px",
                                          "text-align": "right",
                                        },
                                      },
                                      [_vm._v(" 赠品售价限额（元） ")]
                                    ),
                                    _c("el-input", {
                                      staticStyle: { width: "160px" },
                                      attrs: {
                                        size: "mini",
                                        placeholder: "请输入金额",
                                      },
                                      model: {
                                        value:
                                          _vm.form.pricePermissionsReq
                                            .giftPriceLimit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.pricePermissionsReq,
                                            "giftPriceLimit",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.pricePermissionsReq.giftPriceLimit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "marT10" },
                            [
                              _c("el-col", { attrs: { span: 2 } }, [
                                _c("div", { staticClass: "column x-c" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "fontS14",
                                      staticStyle: {
                                        width: "150px",
                                        height: "30px",
                                        "text-align": "right",
                                      },
                                    },
                                    [_vm._v(" 改价/抹零权限 ")]
                                  ),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "marL50 width120",
                                        on: { change: _vm.priceCheckAllFn4 },
                                        model: {
                                          value:
                                            _vm.form.pricePermissionsReq
                                              .priceChangeErasePermissionsCheckAll,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.pricePermissionsReq,
                                              "priceChangeErasePermissionsCheckAll",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.pricePermissionsReq\n                        .priceChangeErasePermissionsCheckAll\n                    ",
                                        },
                                      },
                                      [_vm._v("全选 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.pricePermissionsReq
                                              .isAllowedPriceChangePermissions,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.pricePermissionsReq,
                                              "isAllowedPriceChangePermissions",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.pricePermissionsReq.isAllowedPriceChangePermissions\n                    ",
                                        },
                                      },
                                      [_vm._v("改价 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _c("cardTitleCom", {
              attrs: { cardTitle: "功能权限" },
              scopedSlots: _vm._u([
                {
                  key: "cardContent",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "padd10" },
                        [
                          _c(
                            "el-row",
                            { staticClass: "marT10" },
                            [
                              _c("el-col", { attrs: { span: 2 } }, [
                                _c("div", { staticClass: "column x-c" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "fontS14",
                                      staticStyle: {
                                        width: "150px",
                                        height: "30px",
                                        "text-align": "right",
                                      },
                                    },
                                    [_vm._v(" 交接班权限 ")]
                                  ),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "marL50",
                                        staticStyle: { width: "120px" },
                                        on: { change: _vm.priceCheckAllFn12 },
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowedChangeShiftsCheckAll,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowedChangeShiftsCheckAll",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq\n                        .isAllowedChangeShiftsCheckAll\n                    ",
                                        },
                                      },
                                      [_vm._v("全选 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowedChangeShiftsReconciliation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowedChangeShiftsReconciliation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq\n                        .isAllowedChangeShiftsReconciliation\n                    ",
                                        },
                                      },
                                      [_vm._v("交款对账")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowedGoodsReconciliation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowedGoodsReconciliation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq.isAllowedGoodsReconciliation\n                    ",
                                        },
                                      },
                                      [_vm._v("商品对账")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "marT10" },
                            [
                              _c("el-col", { attrs: { span: 2 } }, [
                                _c("div", { staticClass: "column x-c" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "fontS14",
                                      staticStyle: {
                                        width: "150px",
                                        height: "30px",
                                        "text-align": "right",
                                      },
                                    },
                                    [_vm._v(" 会员权限 ")]
                                  ),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "marL50",
                                        staticStyle: { width: "120px" },
                                        on: { change: _vm.priceCheckAllFn6 },
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowedVipAuthorityCheckAll,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowedVipAuthorityCheckAll",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq\n                        .isAllowedVipAuthorityCheckAll\n                    ",
                                        },
                                      },
                                      [_vm._v("全选 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowedAddVip,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowedAddVip",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.functionPermissionsReq.isAllowedAddVip",
                                        },
                                      },
                                      [_vm._v("新增会员 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowedEditVip,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowedEditVip",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.functionPermissionsReq.isAllowedEditVip",
                                        },
                                      },
                                      [_vm._v("编辑会员 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowModifyVipTelephone,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowModifyVipTelephone",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq.isAllowModifyVipTelephone\n                    ",
                                        },
                                      },
                                      [_vm._v("变更会员手机号 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowDeltaNegative,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowDeltaNegative",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.functionPermissionsReq.isAllowDeltaNegative",
                                        },
                                      },
                                      [_vm._v("会员卡充负值 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "marT10" },
                            [
                              _c("el-col", { attrs: { span: 2 } }, [
                                _c("div", { staticClass: "column x-c" }, [
                                  _c("div", {
                                    staticClass: "fontS14",
                                    staticStyle: {
                                      width: "150px",
                                      height: "30px",
                                    },
                                  }),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width120 marL50",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowNoCardQueryVipInfo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowNoCardQueryVipInfo",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq.isAllowNoCardQueryVipInfo\n                    ",
                                        },
                                      },
                                      [_vm._v("无卡查询会员 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowGrantVipCard,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowGrantVipCard",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.functionPermissionsReq.isAllowGrantVipCard",
                                        },
                                      },
                                      [_vm._v("允许发放会员卡 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "marL50 width120",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowModifyGiftAmount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowModifyGiftAmount",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq.isAllowModifyGiftAmount\n                    ",
                                        },
                                      },
                                      [_vm._v("修改赠送金额 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width120",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowModifyAmountAuthority,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowModifyAmountAuthority",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq.isAllowModifyAmountAuthority\n                    ",
                                        },
                                      },
                                      [_vm._v("允许修改充值金额 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 2 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowReplaceCard,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowReplaceCard",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.functionPermissionsReq.isAllowReplaceCard",
                                        },
                                      },
                                      [_vm._v("会员卡换卡 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowModifyCardStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowModifyCardStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq.isAllowModifyCardStatus\n                    ",
                                        },
                                      },
                                      [_vm._v("会员卡状态修改 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowModifyCardLevel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowModifyCardLevel",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq.isAllowModifyCardLevel\n                    ",
                                        },
                                      },
                                      [_vm._v("允许会员卡级别修改 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "marT10" },
                            [
                              _c("el-col", { attrs: { span: 2 } }, [
                                _c("div", { staticClass: "column x-c" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "fontS14",
                                      staticStyle: {
                                        width: "150px",
                                        height: "30px",
                                        "text-align": "right",
                                      },
                                    },
                                    [_vm._v(" 收银员常规操作 ")]
                                  ),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "marL50 width120",
                                        on: { change: _vm.priceCheckAllFn7 },
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowedNormalOperationCheckAll,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowedNormalOperationCheckAll",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq\n                        .isAllowedNormalOperationCheckAll\n                    ",
                                        },
                                      },
                                      [_vm._v("全选 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowCashier,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowCashier",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.functionPermissionsReq.isAllowCashier",
                                        },
                                      },
                                      [_vm._v("允许收银 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 2 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowOpenCashBox,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowOpenCashBox",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.functionPermissionsReq.isAllowOpenCashBox",
                                        },
                                      },
                                      [_vm._v("开启钱箱 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowAddGoods,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowAddGoods",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.functionPermissionsReq.isAllowAddGoods",
                                        },
                                      },
                                      [_vm._v("新增商品 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "marT10" },
                            [
                              _c("el-col", { attrs: { span: 2 } }, [
                                _c("div", { staticClass: "column x-c" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "fontS14",
                                      staticStyle: {
                                        width: "150px",
                                        height: "30px",
                                        "text-align": "right",
                                      },
                                    },
                                    [_vm._v(" 单据权限 ")]
                                  ),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "marL50 width120",
                                        on: { change: _vm.priceCheckAllFn9 },
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowedBillAuthorityCheckAll,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowedBillAuthorityCheckAll",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq\n                        .isAllowedBillAuthorityCheckAll\n                    ",
                                        },
                                      },
                                      [_vm._v("全选 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowFrontRefund,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowFrontRefund",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.functionPermissionsReq.isAllowFrontRefund",
                                        },
                                      },
                                      [_vm._v("前台退货 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowSupplementTicket,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowSupplementTicket",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq.isAllowSupplementTicket\n                    ",
                                        },
                                      },
                                      [_vm._v("补打小票 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowRegistrationAndRetrievalOrders,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowRegistrationAndRetrievalOrders",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq\n                        .isAllowRegistrationAndRetrievalOrders\n                    ",
                                        },
                                      },
                                      [_vm._v("挂单/取单 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowDelRegistrationOrder,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowDelRegistrationOrder",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq.isAllowDelRegistrationOrder\n                    ",
                                        },
                                      },
                                      [_vm._v("删除挂单单据 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowCancellation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowCancellation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.functionPermissionsReq.isAllowCancellation",
                                        },
                                      },
                                      [_vm._v("取消预订 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "marT10" },
                            [
                              _c("el-col", { attrs: { span: 2 } }, [
                                _c("div", { staticClass: "column x-c" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "fontS14",
                                      staticStyle: {
                                        width: "150px",
                                        height: "30px",
                                        "text-align": "right",
                                      },
                                    },
                                    [_vm._v(" 数据查看权限 ")]
                                  ),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "marL50 width120",
                                        on: { change: _vm.priceCheckAllFn5 },
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowedDataPermissionsCheckAll,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowedDataPermissionsCheckAll",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq\n                        .isAllowedDataPermissionsCheckAll\n                    ",
                                        },
                                      },
                                      [_vm._v("全选 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowedLocalShopStock,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowedLocalShopStock",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq.isAllowedLocalShopStock\n                    ",
                                        },
                                      },
                                      [_vm._v("查看本店库存 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.functionPermissionsReq
                                              .isAllowedAllStoreStock,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.functionPermissionsReq,
                                              "isAllowedAllStoreStock",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.functionPermissionsReq.isAllowedAllStoreStock\n                    ",
                                        },
                                      },
                                      [_vm._v("查看全部仓库库存 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _c("cardTitleCom", {
              attrs: { cardTitle: "报表查询权限" },
              scopedSlots: _vm._u([
                {
                  key: "cardContent",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "padd10" },
                        [
                          _c(
                            "el-row",
                            { staticClass: "marT10" },
                            [
                              _c("el-col", { attrs: { span: 2 } }, [
                                _c("div", { staticClass: "column x-c" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "fontS14",
                                      staticStyle: {
                                        width: "150px",
                                        height: "30px",
                                        "text-align": "right",
                                      },
                                    },
                                    [_vm._v(" 报表查看权限 ")]
                                  ),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "marL50",
                                        staticStyle: { width: "120px" },
                                        on: { change: _vm.priceCheckAllFn13 },
                                        model: {
                                          value:
                                            _vm.form.reportPermissionsReq
                                              .isAllowedReportFormsCheckAll,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.reportPermissionsReq,
                                              "isAllowedReportFormsCheckAll",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.reportPermissionsReq.isAllowedReportFormsCheckAll\n                    ",
                                        },
                                      },
                                      [_vm._v("全选 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width120 marL50",
                                        model: {
                                          value:
                                            _vm.form.reportPermissionsReq
                                              .isAllowedSalesIcon,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.reportPermissionsReq,
                                              "isAllowedSalesIcon",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.reportPermissionsReq.isAllowedSalesIcon",
                                        },
                                      },
                                      [_vm._v("销售图表")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width120 marL50",
                                        model: {
                                          value:
                                            _vm.form.reportPermissionsReq
                                              .isAllowedSalesSummary,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.reportPermissionsReq,
                                              "isAllowedSalesSummary",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.reportPermissionsReq.isAllowedSalesSummary",
                                        },
                                      },
                                      [_vm._v("销售汇总")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width80",
                                        model: {
                                          value:
                                            _vm.form.reportPermissionsReq
                                              .isAllowedHandoverReconciliation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.reportPermissionsReq,
                                              "isAllowedHandoverReconciliation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.reportPermissionsReq\n                        .isAllowedHandoverReconciliation\n                    ",
                                        },
                                      },
                                      [_vm._v("交班对账记录 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width120 marL50",
                                        model: {
                                          value:
                                            _vm.form.reportPermissionsReq
                                              .isAllowedCashierReceipt,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.reportPermissionsReq,
                                              "isAllowedCashierReceipt",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.reportPermissionsReq.isAllowedCashierReceipt\n                    ",
                                        },
                                      },
                                      [_vm._v("收银小票查询")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width120 marL50",
                                        model: {
                                          value:
                                            _vm.form.reportPermissionsReq
                                              .isAllowedPaymentMethodsSummary,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.reportPermissionsReq,
                                              "isAllowedPaymentMethodsSummary",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.reportPermissionsReq.isAllowedPaymentMethodsSummary\n                    ",
                                        },
                                      },
                                      [_vm._v("收款方式汇总")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "column x-c" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "width120 marL50",
                                        model: {
                                          value:
                                            _vm.form.reportPermissionsReq
                                              .isAllowedVipRechargeQuery,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.reportPermissionsReq,
                                              "isAllowedVipRechargeQuery",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      form.reportPermissionsReq.isAllowedVipRechargeQuery\n                    ",
                                        },
                                      },
                                      [_vm._v("会员充值查询")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }