var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap x-f",
      attrs: { id: "wrap" },
    },
    [
      _c("categoryTreeCard", {
        attrs: {
          categoryTreeTitle: "收银员分组",
          isEditBtn: true,
          boxHeight: _vm.boxHeight,
          boxInternalHeight: _vm.boxInternalHeight,
          categoryTreeOptions: _vm.userGroupTreeOptions,
          isOnlyOneLevel: true,
        },
        on: {
          treeNodeId: _vm.getTreeNodeId,
          appendTree: _vm.appendTree,
          editTree: _vm.editTree,
          removeTree: _vm.removeTree,
        },
      }),
      _c(
        "div",
        { staticClass: "rightBox y-b" },
        [
          _c(
            "cardTitleCom",
            {
              staticClass: "headGoodsSearch",
              attrs: { cardTitle: "查询", id: "search-card" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "navSearch" },
                  [
                    _c("seniorSearch", {
                      attrs: {
                        getList: _vm.getList,
                        dropDownList: _vm.dropDownList,
                        isAdvancedSearch: false,
                        isBillDate: false,
                        isSearchInput: true,
                        isSearchInputTitle: "输入收银员编码、名称、手机号",
                      },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            {
              staticClass: "headGoodsTable",
              attrs: { cardTitle: "收银员信息" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "goodsMessage" },
                  [
                    _c(
                      "operatingButton",
                      {
                        attrs: {
                          getList: _vm.getList,
                          isRecycleBtn: true,
                          isDeleteBtn: false,
                          multiple: _vm.multiple,
                        },
                        on: {
                          handleAdd: _vm.handleAdd,
                          handleDelete: _vm.handleDelete,
                          handleRedoDelete: function ($event) {
                            _vm.dialogOptions.show = true
                          },
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "specialDiyBtn" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goTo("staffJurisdiction")
                                  },
                                },
                              },
                              [_vm._v("授予权限 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goTo("groupJurisdiction")
                                  },
                                },
                              },
                              [_vm._v("分组权限 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: { click: _vm.copyPermission },
                              },
                              [_vm._v("复制权限 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            ref: "multipleTable",
                            attrs: {
                              data: _vm.tableData,
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              width: "100%",
                            },
                            on: {
                              "selection-change": _vm.handleSelectionChange,
                              "row-click": _vm.handleRowClick,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                width: "50",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                type: "index",
                                width: "80",
                                label: "序号",
                              },
                            }),
                            _c("el-table-column", {
                              key: "cashierNo",
                              attrs: {
                                label: "收银员编号",
                                align: "center",
                                prop: "cashierNo",
                                width: "180",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDetail(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.cashierNo) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              key: "cashierName",
                              attrs: {
                                label: "收银员姓名",
                                align: "center",
                                prop: "cashierName",
                                width: "180",
                              },
                            }),
                            _c("el-table-column", {
                              key: "telephone",
                              attrs: {
                                label: "手机号",
                                align: "center",
                                prop: "telephone",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              key: "shopNameStr",
                              attrs: {
                                label: "所属门店",
                                align: "center",
                                prop: "shopNameStr",
                                width: "140",
                              },
                            }),
                            _c("el-table-column", {
                              key: "remark",
                              attrs: {
                                label: "备注",
                                align: "center",
                                prop: "remark",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              key: "updateBy",
                              attrs: {
                                label: "修改人",
                                align: "center",
                                prop: "updateBy",
                                "show-overflow-tooltip": true,
                                width: "140",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "修改时间",
                                align: "center",
                                prop: "updateTime",
                                "show-overflow-tooltip": "",
                                width: "155",
                              },
                            }),
                            _c("el-table-column", {
                              key: "createBy",
                              attrs: {
                                label: "创建人",
                                align: "center",
                                prop: "createBy",
                                "show-overflow-tooltip": true,
                                width: "140",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "创建时间",
                                align: "center",
                                prop: "createTime",
                                "show-overflow-tooltip": "",
                                width: "155",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("bottomPage", {
                          attrs: {
                            getList: _vm.getList,
                            idsCom: _vm.ids,
                            totalCom: _vm.total,
                          },
                          on: { selectAllCom: _vm.selectAll },
                          model: {
                            value: _vm.queryParams,
                            callback: function ($$v) {
                              _vm.queryParams = $$v
                            },
                            expression: "queryParams",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", {
        attrs: {
          title: _vm.cashierTitle,
          width: 600,
          showDialog: _vm.openCashier,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.openCashier = $event
          },
          "update:show-dialog": function ($event) {
            _vm.openCashier = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingDetail,
                        expression: "loadingDetail",
                      },
                    ],
                    ref: "cashierForm",
                    attrs: {
                      model: _vm.cashierForm,
                      rules: _vm.rules,
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "用户组",
                                  prop: "cashierGroupId",
                                },
                              },
                              [
                                _c("treeselect", {
                                  ref: "treeCashier",
                                  attrs: {
                                    options: _vm.userGroupTreeOptions,
                                    "show-count": true,
                                    placeholder: "请选择收银员用户组",
                                    normalizer: _vm.normalizer,
                                    "default-expand-all": true,
                                    clearable: false,
                                  },
                                  on: { input: _vm.inputSelect },
                                  model: {
                                    value: _vm.cashierForm.cashierGroupId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.cashierForm,
                                        "cashierGroupId",
                                        $$v
                                      )
                                    },
                                    expression: "cashierForm.cashierGroupId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "收银员", prop: "cashierId" } },
                              [
                                _c("SelectRemote", {
                                  attrs: {
                                    option: _vm.$select({
                                      key: "listEmployee",
                                      option: {
                                        option: {
                                          showItem: [
                                            {
                                              employeeId:
                                                _vm.cashierForm.cashierId,
                                              employeeName:
                                                _vm.cashierForm.cashierName,
                                            },
                                          ],
                                          labels: [
                                            {
                                              title: "员工编号",
                                              label: "employeeNo",
                                            },
                                            {
                                              title: "员工名称",
                                              label: "employeeName",
                                            },
                                          ],
                                        },
                                      },
                                    }).option,
                                  },
                                  on: {
                                    selectChange: _vm.tenantEmployeeChange,
                                  },
                                  model: {
                                    value: _vm.cashierForm.cashierId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.cashierForm,
                                        "cashierId",
                                        $$v
                                      )
                                    },
                                    expression: "cashierForm.cashierId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "收银员编号",
                                  prop: "cashierNo",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "收银员编号",
                                    maxlength: "11",
                                  },
                                  model: {
                                    value: _vm.cashierForm.cashierNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.cashierForm,
                                        "cashierNo",
                                        $$v
                                      )
                                    },
                                    expression: "cashierForm.cashierNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "手机号", prop: "telephone" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: true,
                                    placeholder: "手机号",
                                    maxlength: "11",
                                  },
                                  model: {
                                    value: _vm.cashierForm.telephone,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.cashierForm,
                                        "telephone",
                                        $$v
                                      )
                                    },
                                    expression: "cashierForm.telephone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 23 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "门店", prop: "shopIdArr" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "addShop" },
                                  [
                                    _c("SelectLocal", {
                                      attrs: {
                                        multiple: true,
                                        option: {
                                          disabled: _vm.disabledShop,
                                          remote: _vm.getAllShopInfo,
                                          labels: [
                                            {
                                              title: "门店编号",
                                              label: "shopNo",
                                            },
                                            {
                                              title: "门店名称",
                                              label: "shopName",
                                            },
                                          ],
                                          value: "shopId",
                                          label: "shopName",
                                        },
                                      },
                                      model: {
                                        value: _vm.cashierForm.shopIdArr,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.cashierForm,
                                            "shopIdArr",
                                            $$v
                                          )
                                        },
                                        expression: "cashierForm.shopIdArr",
                                      },
                                    }),
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "allShop",
                                        model: {
                                          value: _vm.checkedAllShop,
                                          callback: function ($$v) {
                                            _vm.checkedAllShop = $$v
                                          },
                                          expression: "checkedAllShop",
                                        },
                                      },
                                      [_vm._v("全部 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "备注", prop: "remark" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    placeholder:
                                      "备注长度介于 1 和 30 字符之间",
                                    maxlength: "30",
                                    autosize: { minRows: 2, maxRows: 4 },
                                  },
                                  model: {
                                    value: _vm.cashierForm.remark,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.cashierForm, "remark", $$v)
                                    },
                                    expression: "cashierForm.remark",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.cashierTitle === "新增收银员管理"
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              textalign: "center",
                              color: "#b6b6b6",
                              "margin-left": "100px",
                            },
                          },
                          [
                            _vm._v(
                              " 说明: 将生成随机密码并发送短信消息到收银员手机号上 ! "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { size: "mini" },
                    on: { click: _vm.closeDia },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.saveAndAdd },
                  },
                  [_vm._v("保存并新增")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { size: "mini", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm(false)
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("c-dialog", {
        attrs: {
          title: _vm.userGroupTitle,
          width: 600,
          showDialog: _vm.openUserGroup,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.openUserGroup = $event
          },
          "update:show-dialog": function ($event) {
            _vm.openUserGroup = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.userGroupLoading,
                        expression: "userGroupLoading",
                      },
                    ],
                    ref: "userGroupForm",
                    attrs: { model: _vm.userGroupForm, "label-width": "120px" },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "父用户组名称",
                          prop: "parentCashierGroupName",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.userGroupForm.parentCashierGroupName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.userGroupForm,
                                "parentCashierGroupName",
                                $$v
                              )
                            },
                            expression: "userGroupForm.parentCashierGroupName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "用户组编号", prop: "cashierGroupNo" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            autocomplete: "off",
                            placeholder: "请输入用户组编号",
                          },
                          model: {
                            value: _vm.userGroupForm.cashierGroupNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.userGroupForm, "cashierGroupNo", $$v)
                            },
                            expression: "userGroupForm.cashierGroupNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "用户组名称",
                          prop: "cashierGroupName",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入用户组名称" },
                          model: {
                            value: _vm.userGroupForm.cashierGroupName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.userGroupForm,
                                "cashierGroupName",
                                $$v
                              )
                            },
                            expression: "userGroupForm.cashierGroupName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { size: "mini" },
                    on: { click: _vm.closeDia2 },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { size: "mini", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.groupSubmitForm(false)
                      },
                    },
                  },
                  [_vm._v("保 存")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("c-dialog", {
        attrs: {
          title: "复制权限",
          width: 400,
          showDialog: _vm.permissionOpen,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.permissionOpen = $event
          },
          "update:show-dialog": function ($event) {
            _vm.permissionOpen = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "permissionForm",
                    attrs: { model: _vm.permissionForm, "label-width": "90px" },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "复制对象", prop: "sourceCashierId" } },
                      [
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({
                              key: "listCashier",
                              option: {
                                option: {
                                  buttons: [
                                    {
                                      type: "more",
                                      option: {
                                        title: "选择收银员",
                                        width: 1250,
                                        type: "TreeAndTable",
                                        formData: _vm.cashierDialog,
                                      },
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.permissionForm.sourceCashierId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.permissionForm,
                                "sourceCashierId",
                                $$v
                              )
                            },
                            expression: "permissionForm.sourceCashierId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { size: "mini" },
                    on: {
                      click: function ($event) {
                        _vm.permissionOpen = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.submitPermissionForm },
                  },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Dialog2", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }